'use client'
import { useError } from '@/app/components/providers/ErrorProvider'
import getClientSupabase from '@/app/utils/getClientSupabase'
import {
  Button,
  Divider,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
} from '@nextui-org/react'
import React, { useState, useEffect } from 'react'
import { Database, Tables, Enums } from "@/app/types/supabase";
import { languagesSorted, findLanguage, language } from '@/app/utils/data/platform-languages'
import TemplateModalSelect from './TemplateModalSelect'
import KpiCard from '@/app/components/home/kpiCard'
import JsonAzureSpeech from '@/app/utils/data/azure-speech-languages.json'
import {RadioGroup, Radio, useRadio, VisuallyHidden, RadioProps, cn} from "@nextui-org/react";
import { HeaderMerchantProfile } from '@/app/types/collection'
import { getMerchantFeatures } from '@/app/components/merchantFeatures/getMerchantFeatures'
import { visibilityFind, createVisibilityDropdown } from '@/app/utils/data/mapping-visibility'
import interviewTemplates from '@/app/utils/data/interview-templates.json'
import GenerateQuestionsForm from '../[...interviewId]/GenerateQuestionsForm'
import InfoHelpPopover from '@/app/components/home/infoHelpPopover'

interface itemsType {
  name: string
  key: string
}

interface interviewQuestions {
  question: string
  duration: number
}

export const CustomRadio = (props: RadioProps) => {
  const {
    Component,
    children,
    isSelected,
    description,
    getBaseProps,
    getWrapperProps,
    getInputProps,
    getLabelProps,
    getLabelWrapperProps,
    getControlProps,
  } = useRadio(props);

  return (
    <Component
      {...getBaseProps()}
      className={cn(
        "group inline-flex items-center justify-between hover:bg-content2 flex-row-reverse",
        "max-w-[300px] cursor-pointer border-2 border-default rounded-lg gap-4 p-4",
        "data-[selected=true]:border-primary",
      )}
    >
      <VisuallyHidden>
        <input {...getInputProps()} />
      </VisuallyHidden>
      <span {...getWrapperProps()}>
        <span {...getControlProps()} />
      </span>
      <div {...getLabelWrapperProps()}>
        {children && <span {...getLabelProps()} className='text-black'>{children}</span>}
        {description && (
          <span className="text-small text-foreground opacity-70">{description}</span>
        )}
      </div>
    </Component>
  );
};

export default function CreateNewInterview( { xMojito, typeForm }: { xMojito: HeaderMerchantProfile, typeForm:'interview'|'coaching' } ) {
  const merchantFeatures = getMerchantFeatures(xMojito?.merchant?.merchant_features);
  const supabase = getClientSupabase()
  const { showError } = useError()

  const interviewTemplatesSorted = interviewTemplates.sort((a, b) => {
    const fa = a.name.toLowerCase();
    const fb = b.name.toLowerCase();

    if (fa < fb) { return -1; }
    if (fa > fb) { return 1; }
    return 0;
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState('')
  const [type, setType] = useState<Enums<'type_interview'>>(typeForm);
  const [typeCombo, setTypeCombo] = useState<string>((typeForm==='interview'?'interview_interactive':'coaching_interactive'));
  const [isInteractive, setIsInteractive] = useState<boolean>(true);

  const [language, setLanguage] = useState<language|undefined>(findLanguage('en'));
  const [visibility, setVisibility] = useState<Enums<'type_visibility'>>('merchant_public');
  const [visibilityList, setVisibilityList] = useState<itemsType[]>(createVisibilityDropdown(xMojito?.profile.type, xMojito?.merchant?.interview_subscription_type));
  const [genQuestions, setGenQuestions] = useState<interviewQuestions[]>([]);
  const [genQuestionsAi, setGenQuestionsAi] = useState<interviewQuestions[]>([]);

  const [interviewTemplateId, setInterviewTemplateId] = useState<Tables<'interview_templates'>>();
  const initialValidationState = {
    name: true,
    interviewTemplateId: true,
    language: true,
  }

  const [formValidation, setFormValidation] = useState(initialValidationState)
  const [templateModal, setTemplateModal] = useState<boolean>(false);
  const [step, setStep] = useState(1);
  const [createQuestionsType, setCreateQuestionsType] = useState<'template'|'ai'|'blank'>('template');
  const [createQuestionsFromTemplate, setCreateQuestionsFromTemplate] = useState<string>('');

  const [formDescription, setFormDescription] = useState<string|null>('');
  const [formDescriptionLong, setFormDescriptionLong] = useState<string|null>('');
  const [formCandidateExpectations, setFormCandidateExpectations] = useState<string|null>('');

  function verifyStep1 (): boolean {
    if ((!name) || (!interviewTemplateId) || (!language)) {
      setFormValidation({
        ...formValidation,
        name: !!name,
        interviewTemplateId: !!interviewTemplateId,
        language: !!language
      })
      showError( 'Please fill in all required fields before creating new interview.', 'warning')

      return false;
    } 
    return true;
  }

  function createInterview() {
    if (verifyStep1() && (name) && (interviewTemplateId) && (language)) {
      setIsLoading(true)
      // send email to user

      const myMerchantIDLocal = (visibility==='public'?null:xMojito.merchant.id);

      supabase.from('interview_def_set')
      .insert({ status: 'draft', 
        name: name, 
        interview_template_id: interviewTemplateId.id, 
        visibility: visibility, 
        merchant_id: myMerchantIDLocal, 
        type: type,
        type_credit: (type==='interview'? null : 'interview_coach_manager'),
        speech_language_name: JsonAzureSpeech.find((e)=>(e.code===language.azure_code_default))?.name,
        speech_language_code: language.azure_code_default,
        mojito_language_code: language.code,
        description:((formDescription===null || formDescription==='')?null:formDescription),
        interview_description_long: ((formDescriptionLong===null || formDescriptionLong==='')?null:formDescriptionLong),
        candidate_expectations:(formCandidateExpectations===''?null:formCandidateExpectations)
      })
      .select()
      .single()
      .then((res) => {

        if (res.error) {
          showError(res.error.message, 'error')
        } else {
          // insert welcome and thank you messages
          supabase.rpc('interview_create_default_steps', { 'in_interview_def_set_id':  res.data.id})
          .then((resFunction) => { 
            if ((createQuestionsType==='template') || (createQuestionsType==='ai')) {
              supabase.rpc('interview_create_generated_steps', { in_interview_def_set_id: res.data.id, in_steps:JSON.parse(JSON.stringify((createQuestionsType==='template')?genQuestions:genQuestionsAi)) }).then((resCreateSteps)=>{
                try {
                  if (resCreateSteps.error) { 
                    showError(resCreateSteps.error?.message, 'error');
                  } else {
                    setIsLoading(false)
                    setIsModalOpen(false)
            
                    window.location.href = (type==='interview'?'/interview_creator/':'/jobseekers-coaching/')+res.data.id;
                  }
                } catch (e) { }
              })
            } else {
              setIsLoading(false)
              setIsModalOpen(false)
      
              window.location.href = (type==='interview'?'/interview_creator/':'/jobseekers-coaching/')+res.data.id;
            }
          });
        }
      })
    }
  }

  return (
    <>
      {/* button for showing the modal */}
      <div className={'flex justify-center'}>
        <Button aria-label='Create Interview' color="primary" size='sm' onClick={() => setIsModalOpen(true)}>
          Create Interview
        </Button>
      </div>

      {/* invite individual user modal */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} scrollBehavior='inside' size="3xl">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 text-black">Create new {typeForm==='interview'? 'interview' : 'coaching session'}</ModalHeader>
              <ModalBody className={'flex flex-col gap-5'}>
                {step===1?<div className='flex flex-col gap-5'>
                <RadioGroup
                    label={typeForm==='interview'? 'Interview type' : 'Coaching type'}
                    orientation="horizontal"
                    size='sm'
                    onChange={(e) => { 
                      setTypeCombo(e.target.value);
                      switch (e.target.value) {
                        case 'interview_interactive':
                          setType('interview');
                          setIsInteractive(true);

                          break;

                        case 'interview_generative':
                          setType('interview');
                          setIsInteractive(false);
                          break;

                        case 'coaching_interactive':
                          setType('coaching');
                          setIsInteractive(true);
                          break;

                        case 'coaching_generative':
                          setType('coaching');
                          setIsInteractive(false);
                          break;

                        case 'persona':
                          setType('persona');
                          setIsInteractive(true);
                          break;
                      }
                      setInterviewTemplateId(undefined);
                      
                      if (e.target.value==='persona') {
                        setCreateQuestionsType('blank')
                      } else {
                        setCreateQuestionsType('template')
                      }
                    }}
                    value={typeCombo}
                    classNames={{wrapper: 'grid grid-cols-3'}}
                  >
                    {typeForm==='interview' && <Radio value="interview_interactive" description="Interview created in real-time"><span className='text-black'>Interview (interactive)</span></Radio> }
                    {typeForm==='interview' && <Radio value="interview_generative" description="Interview follow a precise sequence"><span className='text-black'>Interview (generative)</span></Radio> }
                    {typeForm==='coaching' && <Radio value="coaching_interactive" description="Coaching session created in real-time"><span className='text-black'>Coaching (interactive)</span></Radio> }
                    {typeForm==='coaching' && <Radio value="coaching_generative" description="Coaching session follow a precise sequence"><span className='text-black'>Coaching (generative)</span></Radio> }
                    {typeForm==='coaching' && <Radio value="persona" description="Avatar is playing a unique role, not following a typical Q&A format"><span className='text-black'>Persona</span></Radio> }
                </RadioGroup>


                <Input
                  aria-label='Position Name'
                  classNames={{ label: 'text-black' }}
                  radius={'sm'}
                  label={'Position Name'}
                  labelPlacement='inside'
                  isRequired
                  placeholder="Enter interview name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  isInvalid={!formValidation.name}
                  name="name"
                />
                
                <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                <Select 
                    aria-label='Visibility'
                    items={visibilityList}
                    disallowEmptySelection={true}
                    size='sm'
                    defaultSelectedKeys={
                      visibilityList
                          ? [visibility]
                          : 'merchant_public'
                      }    
                    onChange={(e) => {
                        const element = visibilityList.find((element) => element.key === e.target.value);
                        if (element?.key) {
                            setVisibility(element.key as Enums<'type_visibility'> );
                        }
                    }}
                    endContent={<InfoHelpPopover type='interview-visibility' />}
                    label="Visibility" placeholder="Select visibility" >
                    {(item) => <SelectItem key={item.key}>{item.name}</SelectItem>}
                </Select>

                <Select
                    label="Language"
                    placeholder="Select Language"
                    variant='flat'
                    size='sm'
                    disallowEmptySelection={true}
                    defaultSelectedKeys={(language?[language.code]:[])}
                    className="w-full"
                    isInvalid={!formValidation.language}
                    onChange={(e)=>{
                        const lang = findLanguage(e.target.value);
                        if (lang) {
                          setInterviewTemplateId(undefined);
                          setLanguage(lang);
                          setGenQuestions([]);
                          setCreateQuestionsFromTemplate('');
                        }

                    }}
                >
                    {languagesSorted.filter((l)=>l.enable_avatar_templates).map((lang)=> {
                        return <SelectItem startContent={lang.flag_emoji} key={lang.code} value={lang.code}>
                            {lang.name_english}
                        </SelectItem>
                    })}
                </Select>
                </div>

                <div className={'flex flex-row flex-wrap gap-4 mb-5 '}>
                    <div className={`grid grid-cols-2 w-full gap-5 items-center`}>
                        <div className={`flex flex-col`}>
                            <div className={'text-xs'}>Avatar:</div>
                            {interviewTemplateId ?
                            <div className='relative flex justify-center w-full aspect-video p-0 m-0 place-items-center '>
                                {(interviewTemplateId.background_url!==null) && <img alt='Interview background' className='absolute rounded-2xl resize-none' style={(interviewTemplateId.background_blur!==null)?{filter: `blur(${interviewTemplateId.background_blur*10}px)`, WebkitFilter: `blur(${interviewTemplateId.background_blur*10}px)`}:{}} src={interviewTemplateId.background_url} /> }
                                {(interviewTemplateId.video_avatar_image_url!==null) && <img alt='Interview avatar' className='absolute resize-none h-full' src={interviewTemplateId.video_avatar_image_url} /> }
                            </div>
                            :
                            <div className={'w-full aspect-video items-center content-middle justify-center flex rounded-2xl '+(!formValidation.interviewTemplateId ? 'bg-danger-50':'bg-default-100')}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                                </svg>
                            </div>
                            }
                        </div>
                        <div>
                          <Button
                              aria-label='Change background'
                              color="secondary"
                              size="sm"
                              variant='light'
                              onClick={() => setTemplateModal (true) }
                          >Change Avatar</Button>
                        </div>
                    </div>
                    <TemplateModalSelect
                        selectedTemplate={interviewTemplateId}
                        searchLanguageName={language?.name_local||'English'}
                        searchLanguageCode={language?.code||'en'}
                        searchLanguageFlag={language?.flag_emoji||''}
                        searchInteractive={isInteractive}
                        myMerchantId={xMojito.merchant.id}
                        showModal={templateModal}
                        handleClose={ () => setTemplateModal (false)}
                        handleSelect={(sel)=> {
                            if (sel) {
                                setInterviewTemplateId(sel);
                                if (sel.type!=='interactive_heygen' && type==='persona') {
                                  setType('coaching')
                                }
                            }
                        }}
                    />
                </div>
                </div>
                :<div className='flex flex-col gap-5'>
                  <RadioGroup 
                    label="Interview content"
                    value={createQuestionsType}
                    onChange={(e) => { setCreateQuestionsType(e.target.value as 'template'|'ai'|'blank');}}
                  >
                    <div className='grid grid-cols-3 gap-3'>
                      <CustomRadio isDisabled={type==='persona'} description="Select interview from our template library" value="template">
                        From template
                      </CustomRadio>
                      <CustomRadio isDisabled={type==='persona'} description="Use AI to generate the questions" value="ai">
                        Generate using AI
                      </CustomRadio>
                      <CustomRadio
                        description="Start from scratch"
                        value="blank"
                      >
                        Start from scratch
                      </CustomRadio>
                    </div>
                  </RadioGroup>

                  {createQuestionsType==='template' &&
                  <div className='flex flex-col gap-5'>
                    <Divider />
                    <Select
                        label="Available job position templates"
                        placeholder="Select Job position"
                        variant='flat'
                        size='sm'
                        disallowEmptySelection={true}
                        className="w-full"
                        defaultSelectedKeys={(createQuestionsFromTemplate!==''?[createQuestionsFromTemplate]:[])}
                        onChange={(e)=>{
                            const found = interviewTemplatesSorted.find((l)=>l.mojito_language_code+'-'+l.name===e.target.value);
                            if (found) {
                              setGenQuestions(found.questions);
                              setCreateQuestionsFromTemplate(e.target.value);
                              setFormDescription(found.description);
                              setFormDescriptionLong(found.long);
                            }
                        }}
                    >
                        {interviewTemplatesSorted.filter((l)=>l.mojito_language_code===language?.code).map((template)=> {
                            return <SelectItem key={template.mojito_language_code+'-'+template.name}>
                                {template.name}
                            </SelectItem>
                        })}
                    </Select>
                    
                    <div>
                      <div className='text-lg font-bold text-black mb-2'>Questions from template</div>
                      {genQuestions.length>0 ?
                        <>
                        <div className='mb-2'>
                          <ol className='with-numbers text-black'>
                            {genQuestions.map((q)=> {
                              return <li key={q.question}>{q.question}</li>
                            })}
                          </ol>
                        </div>
                        <div className='text-sm'>Note: You can edit / customize interview questions before publishing the interview.</div>
                        </>
                        :
                        <div className='text-sm'>No templated selected</div>
                      }
                    </div>
                  </div>
                  }

                  {createQuestionsType==='ai' &&
                    <div className='flex flex-col gap-5'>
                      <Divider />
                
                      <GenerateQuestionsForm 
                          interviewId={null}
                          merchantId={xMojito.merchant.id}
                          status={'draft'}
                          visibility={visibility}
                          questionsGenerated={genQuestionsAi}
                          type={type}
                          description={formDescription}
                          candidate_expectations={formCandidateExpectations}
                          name={name}
                          mojito_language_code={language?.code || 'en'}
                          onFieldChange={(key, value)=> { 
                            if (key==='description') setFormDescription(value);
                            if (key==='candidate_expectations') setFormCandidateExpectations(value);
                          }}
                          onQuestions={(questions)=> {
                            setGenQuestionsAi(questions);
                          }}
                      />
                    </div>
                  }

                </div>
              }
              </ModalBody>
              <ModalFooter className='items-end'>
                {step===2 && 
                  <Button aria-label='Close' size='md' variant='bordered' onPress={()=> { setStep(1); }}>Back</Button>
                }
                {step===1 && 
                <Button
                  className={'ml-5 '}
                  isLoading={isLoading}
                  color={'primary'}
                  size={'md'}
                  radius={'sm'}
                  onPress={ ()=> { if (verifyStep1()) setStep(2); }}
                >
                  Next
                </Button>
                }

                {step===2 && 
                <Button
                  className={'ml-5 '}
                  isLoading={isLoading}
                  color={'primary'}
                  size={'md'}
                  radius={'sm'}
                  isDisabled={((createQuestionsType==='template') && (genQuestions.length===0)) || ((createQuestionsType==='ai') && (genQuestionsAi.length===0)) }
                  onPress={createInterview}
                >
                  Create
                </Button>
                }
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
